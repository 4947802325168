<template>
  <div>
    <div class="row align-items-end">
      <div class="col-6">
        <label for="">Désignation <span class="text-danger">*</span> </label>
        <input type="text" v-model="deposit.fullName" class="form-control" />
      </div>

      <div class="col-3">
        <label for="">Responsable</label>
        <input type="text" v-model="deposit.responsable" class="form-control" />
      </div>

      <div class="col-3">
        <label for="">Nº de téléphone </label>
        <input
          type="text"
          v-model="deposit.phoneResponsable"
          class="form-control"
        />
      </div>
    </div>
    <br />
    <div class="row align-items-end">
      <div class="col-6">
        <label for="">Adresse</label>
        <input type="text" v-model="deposit.address" class="form-control" />
      </div>

      <div class="col-3">
        <label for="">Ville</label>
        <input type="text" v-model="deposit.city" class="form-control" />
      </div>

      <div class="col-3">
        <label for="">Code postale </label>
        <input type="text" v-model="deposit.zipCode" class="form-control" />
      </div>
    </div>
    <br />
    <div class="row justify-content-end align-items-end">
      <div class="col-auto">
        <button class="btn btn-success" @click="save(deposit)">
          <i class="bi bi-check-square-fill"></i> Enregistrer
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      deposit: {},
    };
  },
  computed: {},
  methods: {
    async save(data) {
      await this.$store.dispatch("deposit/store", data);
    },
  },
  beforeMount() {},
};
</script>
